.container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-black);
}

[data-theme='dark'] .container {
	color: var(--color-white);
}
